@charset "UTF-8";
/* CSS Document */

/* CSS Reset */

html{scroll-behavior:smooth}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
    font-family: 'Carters'; /* Give your font a name */
    src: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/files/Carters.woff') format('woff'), /* Specify the font file path */
    /* Add additional font properties if needed, such as font-weight and font-style */
}

body {
    font-family: 'Poppins', sans-serif;
	background-color: #f0f0ea;
	color: #006d86;
}

h1, h2, h3, h4, h5 {
    font-family: 'Carters', sans-serif !important; /* Use your custom font for headings */
	margin-bottom: .5em;
	letter-spacing: -3px;
	color: #006d86;
	text-transform: uppercase;
	line-height: .9;
}

h1 {
	font-size: 3.8em;
}

h2 {
	font-size: 3.3em;
}


h3 {
	font-size: 2.5em;
}

p {
	margin-bottom: 1.5em;
	line-height: 1.1;
	font-size: 1.2em;
	letter-spacing: 0;
	opacity: 1;
}

@media only screen and (max-width: 480px) {
	  h1 {
		font-size: 2.8em;
	}

	h2 {
		font-size: 2.3em;
	}


	h3 {
		font-size: 2em;
	}

	p {
		margin-bottom: 1.5em;
		line-height: 1.1;
		font-size: 1em;
	}

}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

a {
	color: #006d86;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

@media only screen and (min-width: 481px) {
	.page-width {
		width: 1100px !important;
		padding: 0 !important
	}
}

.clearfix {
	clear: both;
	display: block !important;
	height: 1px;
}

.LS_button {
	background-color: #006d86;
	color: #fff;
	border-radius: 10px;
	padding: 16px 20px;
	text-decoration: none;
	display: inline-block;
		transition: .3s ease-out;

}

.LS_button:hover {
	scale: 1.1;
	text-decoration: none;
}


/* Shopify CSS */

.cart-notification {
  z-index: 10000 !important;
  background-color: #fff;
}

.header__icon--cart {
	position: absolute;
	top: 33px;
	right: 0;
	color: #fff !important;
}

@media only screen and (max-width: 480px) {
	.header__icon--cart {
		top: 2px;
		right: 22px;
	}
	
	.LS_button {
		width: 100%;
		text-align: center;
		display: block;

	}

}

.pagination__item--current {
	border-radius: 100px;
    color: #fff !important;
    text-decoration: none;
    background-color: #006d86;
    opacity: 1;
	font-size: 1.4em;
    font-weight: bold;
}

.pagination__item--current:before {
    text-decoration: none;
}

.light {
	opacity: 1;
}

.color-scheme-1 {
	color: #006d86 !important;
}

.cart-notification {
    border-radius: 30px;
}

@media only screen and (max-width: 480px) {

	.product-count {
		margin-top: -1em !important;
		margin-bottom: 1em !important;
		float: left !important;
		text-align: left !important;
		width: 100% !important;
	}
	
	.mobile-facets__wrapper { 
		display: none!important;	
	}
}



/* LS Code */

.LS_header_container {
	background: rgb(0,109,134);
	background: -moz-linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,0) 100%);
	background: -webkit-linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,0) 100%);
	background: linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006d86",endColorstr="#006d86",GradientType=1);
	height: 180px;
	width: 100%;
	z-index: 1000;
}

.LS_header_container_curve {
	background-image: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_menu_curve.png');
    background-size: 100%; /* Cover the entire container */
    background-position: bottom center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.LS_header {
	margin: 0 auto;
	text-align: center;
	width: 1100px;
	position: relative;
}

.LS_header_logo {
	width: 250px;
	position: absolute;
	left: 0;
	right: 0;
	top: 15px;
	margin: 0 auto;
}

.LS_header_search {
	top: 50px;
	left: 0px;
	position: absolute;
}

.LS_header_search img {
	width: 36px;
	height: 36px;
}

/*.LS_header_cart {
	top: 50px;
	right: 0px;
	position: absolute;
}

.LS_header_cart img {
	width: 36px;
	height: 36px;
} */


.LS_header_left a, .LS_header_right a{
	color: #fff;
	text-decoration: none;
	font-size: 1.2em;
	font-weight: 100;
	letter-spacing: -.1px;
}

.LS_header_left {
	float: left;
	margin-top: 60px;
	margin-left: 90px;
}

.LS_header_left a {
	margin: 0 42px;
}

.LS_header_right {
	float: right;
	margin-top: 60px;
	margin-right: 90px;
}

.LS_header_right a{
	margin: 0 15px;
}

.LS_header_images {
	width: 100%;
	height: 80vh;
	background-image: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_header_home_1.jpg');
    /* You can use different properties to control how the image is displayed */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
	position: relative;
	margin-bottom: 50px;
	transition: opacity .33s ease-in-out;
	margin-top: -150px;
}

.LS_header_images_accom {
	width: 100%;
	height: 80vh;
	background-image: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_header_accom_header.jpg');
    /* You can use different properties to control how the image is displayed */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
	position: relative;
	margin-bottom: 50px;
	margin-top: -150px;
}


.LS_header_images_curve {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}


.LS_content_container {
	margin: 0 auto;
	width: 1100px;
	position: relative;
}

.LS_header_callout {
	background-color: #006d86;
	border-radius: 30px;
	padding: 50px 50px 30px 50px;
	width: 500px;
	text-align: left;
	color: #fff;
	float: right;
	margin-top: -335px;
}

.LS_header_callout_accom {
	margin-top: -538px;
}


.LS_header_callout_accom p{
	margin-bottom: 1em !important;
}

.LS_header_callout h1 {
	color: #fff;	
}

.LS_products_placeholder {
	width: 100%;
	margin-bottom: 100px;
}

.LS_header_mobile {
	background: rgb(0,109,134);
	background: -moz-linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,0) 100%);
	background: -webkit-linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,0) 100%);
	background: linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006d86",endColorstr="#006d86",GradientType=1);
	height: 110px;
	width: 100%;
	z-index: 1000;
	display: none;
	transition: .2s ease-out;
	position: fixed;
}

.LS_header_mobile_padding {
	height: 110px;
    display: none !important;	
}

.LS_header_mobile_curve {
	background-image: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_menu_curve.png');
    background-size: cover; /* Cover the entire container */
    background-position: bottom center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.LS_header_mobile.open {
	height: 100vh;
	background: rgb(0,109,134);
	background: -moz-linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,1) 100%);
	background: -webkit-linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,1) 100%);
	background: linear-gradient(180deg, rgba(0,109,134,1) 50%, rgba(0,109,134,1) 100%);
}

.LS_header_mobile_logo {
	width: 150px;
	position: absolute;
	left: 0;
	right: 0;
	top: 10px;
	margin: 0 auto;
}

.LS_header_mobile_menuicon {
	top: 20px;
	left: 20px;
	position: absolute;
	z-index: 10000;
}

.LS_header_mobile_menuicon img {
	width: 30px;
	height: 30px;
}


.LS_header_mobile.open .LS_header_mobile_menuicon {
	display: none;
}



.LS_header_mobile_menuicon_close {
	top: 20px;
	left: 20px;
	position: absolute;
	display: none;
	z-index: 10000;
}

.LS_header_mobile.open .LS_header_mobile_menuicon_close {
	display: block;
}

.LS_header_mobile_menuicon_close img {
	width: 30px;
	height: 30px;
}


.LS_header_mobile_cart {
	top: 20px;
	right: 20px;
	position: absolute;
}

.LS_header_mobile_cart img {
	width: 30px;
	height: 30px;
}

.LS_header_mobile_links {
	color: #fff !important;
	text-align: center;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100vw;
	margin: 0 auto;
	display: none;
	transition: .2s ease-out;
}

.LS_header_mobile.open .LS_header_mobile_links {
	display: block;
}


.LS_header_mobile_links a {
	color: #fff !important;
	letter-spacing: -1px;
}



.LS_header_mobile_social {
	width: 40px;
	display: inline-block;
	margin: 20px 10px;
}


.LS_header_mobile_social img{
	width: 40px;
	
}



@media only screen and (max-width: 480px) {
    .LS_header_images_accom {
    	background-image: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_home_cottage_image_final.jpg');
      
  }
	
	.LS_header_mobile {
		display: block;	
	}
	
	.LS_header_mobile_padding {
		display: block !important;	
	}

	
	.LS_header_container {
		display: none;
	}
	
	.LS_content_container {
		width: 85%;
		margin: 0 auto;
	}
	.LS_header_callout {
		width: 100%;
		float: none;
		margin: -111px auto 50px auto !important;
		padding: 33px 33px 10px 33px;
		border-radius: 15px;
		margin-bottom: 25px;
	}
	
	.LS_products_placeholder {
		width: 100%;
		margin-bottom: 50px;
	}

}

/* Cottages Code */

.LS_cottage_container {
	margin-bottom: 80px;
}

.LS_cottage_slideshow {
	width: 48%;
	height: 500px;
	border-radius: 32px;
	background-image: url('https://cdn.shopify.com/s/files/1/0445/9062/9027/t/14/assets/LS_home_cottage_image_final.jpg');
    /* You can use different properties to control how the image is displayed */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
	float: left;
	margin-right: 40px;
	transition: opacity .33s ease-in-out;
	margin-bottom: 40px;
}

.LS_cottage_content {
	width: 48%;
	float: right;
}


.LS_cottage_reviews {
	clear: both;
}



.LS_cottage_images {
	clear: both;
}

.LS_cottage_images img {
	width: 30%;
	border-radius: 30px;
	float: left;
	margin-right: 5%;
	margin-bottom: 45px;
}


.LS_cottage_images_last {
	margin-right: 0 !important;
	float: right !important;
}

.LS_cottage_hero {
	width: 48%;
	height: 500px;
	border-radius: 32px;
	float: left;
	margin-right: 40px;
	margin-bottom: 40px;
}

.LS_button_airbnb {
  width: 47%;
  float: left;
  text-align: center;
  background-color: #ff5a5f;
  margin-bottom: 1.5em;
}
.LS_button_enquire {
  width: 47%;
  float: right;
  text-align: center;
  margin-bottom: 1.5em;
}

.LS_cottage_ammenities {
  list-style-type: disc;
  margin-bottom: 1em;
}

.LS_cottage_ammenities li {
  margin-left: 1.5em;
  padding-bottom: 7px;
}

@media only screen and (max-width: 480px) {
	 .LS_cottage_slideshow {
		width: 100%;
		height: 280px;
		border-radius: 15px;
		float: none;
		margin-right: 0;
		margin-bottom: 40px;
	}
	
	.LS_cottage_hero {
		width: 100%;
		height: 280px;
		border-radius: 15px;
		float: none;
		margin-right: 0;
		margin-bottom: 40px;
	}

	.LS_cottage_content {
		width: 100%;
		float: none;
	}
	
	.LS_cottage_images img {
		width: 100%;
		border-radius: 15px;
		float: none;
		margin-right: 0;
		margin-bottom: 30px;
	}
	.LS_header_callout_accom .LS_button {
	  width: 100%;
	  text-align: center;
	  margin-bottom: 1em;
	}
	.LS_airbnb_accom {
	  margin-bottom: 0;
	}
	.LS_button_airbnb {
	  width: 100%;
	  float: none;
	 margin-bottom: 1em;
	}
	.LS_button_enquire {
	  width: 100%;
	  float: none;
	}

}



/* Reviews */

.LS_cottage_review {
	width: 30%;
	background-color: #ff5a5f;
	border-radius: 30px;
	padding: 25px;
	text-align: center;
	float: left;
	margin-right: 5%;
	margin-bottom: 45px;
}

.LS_cottage_review_last {
	margin-right: 0;
	float: right;
}

.LS_cottage_review h3{
	margin-bottom: .3em;
	color: #fff;
	letter-spacing: -2px !important;
}


.LS_cottage_review p{
	font-size: 1em;
	margin-bottom: .5em;
	color: #fff;
}


.LS_cottage_review img{
	width: 80%;
	margin: 0 auto;
}

.LS_airbnb {
    width: 100%;
    margin-top: 2em;
}
.LS_airbnb_logo {
  width: 47.5%;
  float: left;
}
.LS_airbnb_rating {
  width: 47.5%;
  float: right;
}
.LS_airbnb_rating img {
  width: 100%;
  margin-bottom: 5px;
}
.LS_airbnb_rating p {
  color: #ff5a5f;
  text-align: center;
  font-size: 1.1em;
}

.LS_airbnb_accom {
  width: 570px;
  margin-top: 0;
  margin-bottom: 2em;
}

.LS_header_callout_accom .LS_button {
  border: solid 2px #fff;
  margin-right: 1em;
}

.LS_cottage_fav {
	width: 100%;
}

@media only screen and (max-width: 480px) {
	.LS_cottage_review {
		width: 100%;
		border-radius: 15px;
		padding: 25px;
		float: none;
		margin-right: 0;
		margin-bottom: 30px;
	}
	
	 .LS_airbnb {
		width: 100%;
	  }
	  .LS_airbnb_rating p {
		font-size: 0.8em;
	  }

}


/* Callouts */

.LS_callout_container {
	margin-bottom: 100px;
}


.LS_callout {
	width: 47.5%;
	background-color: #fff;
	border-radius: 32px;
}

.LS_callout_left {
	float: left;
}


.LS_callout_right {
	float: right;
}


.LS_callout img  {
	width: 100%;
	border-radius: 32px 32px 0 0;
	margin-bottom: 2em;
}

.LS_callout_info {
	padding: 0 30px 30px 30px;
}


@media only screen and (max-width: 480px) {
	.LS_callout_container {
		margin-bottom: 50px;
	}
	
	.LS_callout {
		width: 100%;
		border-radius: 15px;
		margin-bottom: 40px;
	}
	
	.LS_callout_left {
		float: none;
	}


	.LS_callout_right {
		float: none;
	}
	
	.LS_callout img  {
		width: 100%;
		border-radius: 15px 15px 0 0;
		margin-bottom: 2em;
	}

}


/*  Contact Form */

.LS_contact_curve {
	width: 100%;
}


.LS_contact_container_full {
	width: 100%;
	background-color: #006d86;
	color: #fff;
	text-align: left;
	margin-top: -5px;
}


.LS_contact_container {
	text-align: left;
	margin: 0 auto;
	width: 1100px;
	position: relative;
	padding-top: 100px;
}

.LS_contact_info {
	width: 35%;
	float: left;
	color: #fff;
}


.LS_contact_info h2{
	color: #fff;
}

.LS_contact_form {
	width: 60%;
	float: right;
	margin-bottom: 100px;
}

.form-group {
	margin-bottom: 20px;
}

.form-group-left {
	width: 53%;
	float: left;
}

.form-group-right {
	width: 43%;
	float: right;
}


.LS_contact_form .LS_button {
	background-color: #006d86;
	color: #fff;
	border: solid 3px #fff;
	border-radius: 10px;
	padding: 16px 20px;
	text-decoration: none;
	display: inline-block;
}



label {
	display: block;
	margin-bottom: 6px;
	 font-family: 'Carters', sans-serif; /* Use your custom font for headings */
	letter-spacing: 0px;
	text-transform: uppercase;
	line-height: .9;
	font-size: 1.5em;
}

input[type="text"],
input[type="email"],
textarea {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	box-sizing: border-box;
}

textarea {
	height: 150px;
}

input[type="submit"] {
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

input[type="submit"]:hover {
	background-color: #0056b3;
}

@media only screen and (max-width: 480px) {
	.LS_contact_container {
		width: 85%;
		padding-top: 65px;
	}
	
	.LS_contact_info {
		width: 100%;
		float: none;
		border-bottom:  dotted 1px #fff;
		margin-bottom: 40px;
	}

	.LS_contact_form {
		width: 100%;
		float: none;
		margin-bottom: 40px;
	}
	
	.form-group-left {
		width: 100%;
		float: none;
	}

	.form-group-right {
		width: 100%;
		float: none;
	}



}


/* Footer Content */



.LS_footer_container_full {
	width: 100%;
	background-color: #006d86;
	color: #fff;
	text-align: left;}


.LS_footer_container {
	text-align: left;
    margin: -5px auto 0 auto;
	width: 1100px;
	position: relative;
	padding-top: 80px;	
}


.LS_footer_logo {
	float: right;
	width: 280px;
	margin-top: -20px;
}

.LS_footer_section {
	width: 290px;
	float: left;
	height: 300px;
}


.LS_footer_section_menu {
	width: 200px;
}

.LS_footer_social {
	width: 30px;
	display: inline-block;
	margin-right: 10px;
	margin-top: 10px;
}


.LS_footer_social img{
	width: 30px;
	
}

.LS_footer_section h3 {
	color: #fff;
	letter-spacing: -1px;
}

.LS_footer_section p {
	font-size: 1em;
	margin-bottom: .8em;
}

.LS_footer_section a {
	text-decoration: none;
	color: #fff;
}

.LS_footer_section a:hover {
	text-decoration: underline;
}

.LS_phonenumber {
	font-size: 2em !important;
}



.LS_footer_base_container {
	text-align: left;
	margin: 0 auto;
	width: 1100px;
	position: relative;
	padding: 20px 0;
	border-top: solid 1px;
}

.LS_footer_base_container img {
	float: left;
	width: 300px;
	margin-top: -7px;
}

.LS_footer_base_copyright {
	float: right;
	font-size: .6em;
}

@media only screen and (max-width: 480px) {
	.LS_footer_container {
		width: 85%;
		padding-top: 40px;
	}
	
	
	.LS_footer_logo {
		float: none;
		width: 70%;
		margin: -10px auto 20px auto;
		display: block;
	}

	.LS_footer_section {
		width: 100%;
		float: none;
		height: auto;
		margin-bottom: 50px;
	}

	.LS_footer_section_menu {
		width: 100%;
	}

	.LS_footer_section p {
		font-size: 1em;
		margin-bottom: .8em;
	}
	

	.LS_footer_base_container {
		width: 85%;
		padding: 20px 0;
		text-align: center;
	}

	.LS_footer_base_container img {
		float: none;
		width: 300px;
		margin: 0 auto 10px auto;
	}

	.LS_footer_base_copyright {
		float: none;
		font-size: .6em;
	}

	
}


